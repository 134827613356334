import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { HomeworkModel } from "@/models";
import { Common } from "../Common";

export class PaperForm {
  name = '';
  date = '';
  city = '';
  school = '';
  class = '';
  teacher = '';
  grade = '';
  unit = '';
  level = '';
  num = 0;
  hid = '';
  active = '1';
}

type response = { [key: string]: unknown };

@Component<AdminHomeworkPaperEditController>({
  components: {
    SideNav, Lightbox
  }
})
export default class AdminHomeworkPaperEditController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private paperForm = new PaperForm();
  private errorMsg = "";
  private openLB = false;
  private errNo = 0;
  private token = window.localStorage.getItem('adminToken') as string;

  public async created() {
    const item = {
      hid: this.$route.query.hid,
      token: this.token,
    }
    const editData = await HomeworkModel.getPaper(item) as response;
    this.assignDataToForm(editData);
  }

  private assignDataToForm(data: response) {
    Common.checkToken(data.ERR_CODE as number);

    const item = data.info as {
      hid: string;
      name: string;
      date: string;
      city: string;
      school: string;
      class: string;
      teacher: string;
      grade: string;
      unit: string;
      level: string;
      num: number;
      active: string;
    };
    this.paperForm.hid = item.hid;
    this.paperForm.name = item.name;
    this.paperForm.date = item.date;
    this.paperForm.city = item.city;
    this.paperForm.school = item.school;
    this.paperForm.class = item.class;
    this.paperForm.teacher = item.teacher;
    this.paperForm.grade = item.grade;
    this.paperForm.unit = item.unit;
    this.paperForm.level = item.level;
    this.paperForm.num = item.num;
    this.paperForm.active = item.active;
  }
  
  private validateAndSubmit() {
      validate(this.paperForm, { skipMissingProperties: true }).then(
          async result => {
              // if (result.length !== 0) {
              //     this.errorMsgMap = { //先清空error message
              //         sid: '',
              //         name: '',
              //         active: '',
              //     };
              //     result.forEach((item, index) => {
              //         const error = item.constraints as {
              //             [key: string]: string;
              //         };
              //         Object.keys(error).forEach(key => {
              //             this.errorMsgMap[item.property] = error[key]; //放入error message
              //         });
              //     });
              //     return;
              // }

              if (!this.doubleClick) {
                  this.doubleClick = true;

                  const item = { //要傳入的form參數
                    hid: this.paperForm.hid,
                    active: this.paperForm.active,
                    token: this.token,
                  }
                  this.errNo = await HomeworkModel.editPaper(item);
                  Common.checkToken(this.errNo);
                  this.openLB = true;
                  this.doubleClick = false;
                  this.errorMsg = ErrorMessage[ErrorCode.Success];
                  console.log(`=== ${ErrorCode.Success}, ${this.errorMsg}`);
              }
          }
      )
  }

  private closeLB() {
    this.openLB = false;
    if (this.errNo === ErrorCode.Success) {
        this.$router.push("/admin/homework/paper");
    }
  }
}
